import TextComponent from "@/app/_components/TextComponent";
import Button from "@/components/buttons";
import cn from "@/utils/cn";
import { useZStore } from "@/utils/z-store";
import axios from "axios";
import React, { useState, useRef, useEffect } from "react";
import { FaPlus, FaEllipsisV, FaUserAlt, FaAngleDown } from "react-icons/fa";

const ShareDropdown = ({ className = "" }: { className?: string }) => {
  const { data } = useZStore();
  const [isOpen, setIsOpen] = useState(false);
  const [email, setEmail] = useState("");
  const [people, setPeople] = useState<{ email: string; sent: boolean }[]>([]);
  const [showOptions, setShowOptions] = useState<number | null>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (data.userDetails.email)
      setPeople([...people, { email: data.userDetails.email, sent: true }]);
  }, [data]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
        setShowOptions(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const sendInvitation = async (email: string) => {
    if (validateEmail(email)) {
      try {
        const response = await axios.post("/api/send-report-email", {
          email,
          url: window.location.href,
        });

        return true;
      } catch (error) {
        console.error("Error sending email:", error);
        return false;
      }
    }
    return false;
  };
  const toggleDropdown = () => setIsOpen(!isOpen);

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const validateEmail = (email: string) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  const handleAddEmail = async () => {
    if (validateEmail(email) && !people.some((p) => p.email === email)) {
      const sent = await sendInvitation(email);
      setPeople([...people, { email, sent }]);
      setEmail("");
    }
  };
  const handleKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      handleAddEmail();
    }
  };

  const handleRemovePerson = (index: number) => {
    setPeople(people.filter((_, i) => i !== index));
    setShowOptions(null);
  };

  const handleResend = async (index: number) => {
    const person = people[index];
    const sent = await sendInvitation(person.email);
    if (sent) {
      const newPeople = [...people];
      newPeople[index] = { ...person, sent: true };
      setPeople(newPeople);
    }
  };

  const handleCopyLink = () => {
    navigator.clipboard
      .writeText(window.location.href)
      // .then(() => alert("Link copied to clipboard!"))
      .catch((err) => console.error("Failed to copy: ", err));
  };
  return (
    <div className="relative" ref={dropdownRef}>
      {/* Updated Button Design */}
      <button
        onClick={toggleDropdown}
        className={cn(
          "flex items-center justify-center h-[30px] xl:h-[60px] w-[140px] xl:rounded-2xl rounded-[8px] hover:border hover:border-kd-lte-blue text-kd-lte-blue text-lg font-medium bg-[#F9FAFB]",
          className,
        )}
      >
        <FaUserAlt className="mr-2" />
        <TextComponent type="h4" className="text-[#05004E]">
          Share
        </TextComponent>
        <FaAngleDown className="ml-2" />
      </button>

      {isOpen && (
        <div className="absolute right-0 z-10 mt-2 w-[280px] rounded-2xl border border-kd-lte-blue bg-white shadow-lg">
          <div className="">
            {/* Input Section */}
            <div className="flex items-center px-4 py-5 ">
              <div className="mr-2.5 flex size-4 items-center justify-center rounded-full border-[1.4px] border-kd-lte-blue p-0.5">
                <FaPlus className="text-blue-500" size={10} />
              </div>
              <input
                ref={inputRef}
                type="text"
                value={email}
                onChange={handleEmailChange}
                onKeyDown={handleKeyPress}
                className="w-full bg-transparent text-xs text-gray-700 placeholder:text-gray-500 focus:outline-none"
                placeholder="Add email address"
              />
            </div>
            <div className="border border-dashed"></div>

            {/* "People with access" Section */}
            <div className="">
              <div className="flex items-center justify-between p-4">
                <h3 className="text-xs text-gray-700">People with access</h3>
                <Button
                  variant="secondary"
                  className="h-[24px] text-[10px]"
                  onClick={handleCopyLink}
                >
                  <div className="text-[10px]">Copy link</div>
                </Button>
              </div>
              <div className="border-t border-[#EDF2F6]"></div>

              {/* List of People */}
              <div className="divide-y divide-[#EDF2F6]">
                {people.map((person, index) => (
                  <div
                    key={index}
                    className="relative flex items-center justify-between p-4"
                  >
                    <div className="flex items-center text-xs">
                      <div className="mr-2.5 flex size-6 items-center justify-center rounded-full bg-gray-300">
                        <FaUserAlt className="text-white" />
                      </div>
                      <span className="text-sm text-kd-lte-blue">
                        {person.email}
                      </span>
                    </div>

                    <button
                      onClick={() =>
                        setShowOptions(showOptions === index ? null : index)
                      }
                      className="text-gray-500 hover:text-gray-700"
                    >
                      <FaEllipsisV />
                    </button>

                    {/* Options Dropdown */}
                    {showOptions === index && (
                      <div className="absolute right-10 top-4 z-10 mt-2 w-40 rounded-lg border border-kd-lte-blue bg-white text-xs shadow-lg">
                        <div className="flex flex-col py-1">
                          <button
                            className="px-4 py-2 text-left text-gray-700 hover:bg-gray-100"
                            onClick={() => handleResend(index)}
                          >
                            Resend
                          </button>
                          <button
                            onClick={() => handleRemovePerson(index)}
                            className="px-4 py-2 text-left text-[#FA5A7D] hover:bg-gray-100"
                          >
                            Remove access
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ShareDropdown;
