import { useState, useRef, useEffect } from "react";
import {
  FaChevronUp,
  FaChevronDown,
  FaPlus,
  FaEllipsisV,
  FaPencilAlt,
} from "react-icons/fa";
import { useZStore } from "@/utils/z-store";
import Image from "next/image";
import { ReportsActiveIcon, ReportsIcon, ReportsIconBlue } from "../icons";
import cn from "@/utils/cn";
import TextComponent from "@/app/_components/TextComponent";

interface CustomReport {
  id: string;
  name: string;
  type?: "template" | "shared";
}

interface CustomReportDropdown {
  invertColors?: boolean;
}
const CustomReportDropdown: React.FC<CustomReportDropdown> = ({
  invertColors = false,
}) => {
  const { data, actions } = useZStore();
  const [isOpen, setIsOpen] = useState(false);
  const [isCreatingNew, setIsCreatingNew] = useState(false);
  const [newReportName, setNewReportName] = useState("Create New Report");
  const [editingReportId, setEditingReportId] = useState<string | null>(null);
  const [editingReportName, setEditingReportName] = useState("");
  const [showOptionsForId, setShowOptionsForId] = useState<string | null>(null);
  const [hoveredReportId, setHoveredReportId] = useState<string | null>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const selectedReportId = data.selectedCustomReport;
  const customReports = data.customReports || [];

  const selectedReport =
    customReports.find((r) => r.id === selectedReportId)?.name || null;

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        resetState();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if ((isCreatingNew || editingReportId) && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isCreatingNew, editingReportId]);

  const resetState = () => {
    setIsOpen(false);
    setIsCreatingNew(false);
    setNewReportName("Create New Report");
    setShowOptionsForId(null);
    setEditingReportId(null);
    setHoveredReportId(null);
  };

  const toggleDropdown = () => setIsOpen(!isOpen);

  const handleReportSelect = (reportId: string) => {
    actions.setSelectedCustomReport(reportId);
    setShowOptionsForId(null);
  };

  const handleCreateNewClick = () => {
    setIsCreatingNew(true);
    setNewReportName("");
  };

  const handleNewReportNameChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setNewReportName(e.target.value);
  };

  const handleNewReportSubmit = () => {
    if (newReportName.trim() && newReportName !== "Create New Report") {
      const newReportId = actions.addCustomReport(newReportName.trim());
      handleReportSelect(newReportId);
      setIsCreatingNew(false);
      setNewReportName("Create New Report");
    }
  };

  const handleEditReport = (reportId: string, currentName: string) => {
    setEditingReportId(reportId);
    setEditingReportName(currentName);
  };

  const handleEditReportNameChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setEditingReportName(e.target.value);
  };

  const handleEditReportSubmit = () => {
    if (editingReportId && editingReportName.trim()) {
      // actions.updateCustomReport(editingReportId, editingReportName.trim());
      setEditingReportId(null);
    }
  };

  const handleDeleteReport = (reportId: string) => {
    // actions.deleteCustomReport(reportId);
    setShowOptionsForId(null);
  };

  const handleKeyPress = (e: React.KeyboardEvent, isEditing: boolean) => {
    if (e.key === "Enter") {
      isEditing ? handleEditReportSubmit() : handleNewReportSubmit();
    }
  };

  const truncateText = (text: string, maxLength: number) => {
    return text?.length > maxLength
      ? text?.substring(0, maxLength) + "..."
      : text;
  };
  return (
    <div className="relative w-fit" ref={dropdownRef}>
      <button
        onClick={toggleDropdown}
        className={cn(
          // "flex w-[224px] items-center justify-between rounded-2xl bg-kd-lte-blue px-4 py-2 h-[60px] border border-white focus:outline-none",
          "flex items-center space-x-2 justify-between px-4 py-2 h-[60px] focus:outline-none cursor-pointer border-white border",
          "  xl:text-[10.6px]  xl:h-[60px] 2xl:text-base 2xl:rounded-2xl xl:rounded-[10.6px]",
          "h-[30px] text-[8px] flex rounded-lg",
          "2xl:w-full w-full xl:w-full md:w-full",
          invertColors
            ? "bg-[#F9FAFB]"
            : "border hover:border-kd-lte-blue text-kd-lte-blue",
          invertColors
            ? isOpen
              ? "bg-[#F9FAFB] text-[#05004E]"
              : "bg-kd-lte-blue text-white"
            : isOpen
              ? "bg-[#F9FAFB] text-kd-lte-blue"
              : "bg-[#F9FAFB] text-kd-lte-blue",
          // isOpen ? "bg-white text-[#05004E]" : "text-white",
        )}
      >
        <div className="size-5 2xl:size-7">
          {invertColors ? (
            <Image
              alt="Reports icon"
              src={isOpen ? ReportsIcon : ReportsActiveIcon}
              className="mr-2 size-full"
            />
          ) : (
            <Image
              alt="Reports icon"
              src={ReportsIconBlue}
              className="mr-2 size-full"
            />
          )}
        </div>
        <TextComponent
          type="h4"
          className={cn(
            "grow text-nowrap text-left",
            invertColors ? "" : "text-[#05004E]",
          )}
        >
          {truncateText(selectedReport || "Custom Report", 15)}
        </TextComponent>
        {isOpen ? (
          <FaChevronUp
            className={cn(
              !invertColors
                ? isOpen
                  ? "text-kd-lte-blue"
                  : "text-kd-lte-blue"
                : isOpen
                  ? "text-kd-lte-blue"
                  : "text-white",
            )}
          />
        ) : (
          <FaChevronDown
            className={cn(!invertColors ? "text-kd-lte-blue" : "text-white")}
          />
        )}
      </button>

      {isOpen && (
        <div className="absolute right-0 z-10 mt-2 w-[280px] overflow-hidden rounded-2xl border border-[#336dff] bg-white shadow-lg">
          <div className="max-h-80 overflow-y-scroll py-2 pt-0 scrollbar-hide">
            <div className="sticky top-0 z-30 flex items-center border-b border-dashed border-gray-300 bg-white p-4 hover:bg-[#EDF2F6]">
              <div className="mr-2.5 flex size-4 items-center justify-center rounded-full border-[1.4px] border-kd-lte-blue p-0.5">
                <FaPlus className="text-kd-lte-blue" size={10} />
              </div>
              {isCreatingNew ? (
                <input
                  ref={inputRef}
                  type="text"
                  value={newReportName}
                  onChange={handleNewReportNameChange}
                  onKeyDown={(e) => handleKeyPress(e, false)}
                  className="w-full bg-transparent text-xs text-gray-700 focus:outline-none"
                  placeholder="Enter report name"
                />
              ) : (
                <button
                  onClick={handleCreateNewClick}
                  className="text-xs text-kd-lte-blue"
                >
                  Create New Report
                </button>
              )}
            </div>

            {/* <div className="my-2 border-b border-dashed border-gray-300"></div> */}

            {customReports.map((report) => (
              <div
                key={report.id}
                className="relative flex items-center justify-between p-4 hover:bg-[#EDF2F6]"
                onMouseEnter={() => setHoveredReportId(report.id)}
                onMouseLeave={() => setHoveredReportId(null)}
              >
                <button
                  onClick={() => handleReportSelect(report.id)}
                  className="flex grow items-center"
                >
                  {report.id === selectedReportId ? (
                    <div className="mr-2.5 flex size-4 rounded-full border-[1.5px] border-kd-lte-blue">
                      <div className="m-auto size-2 rounded-full bg-kd-lte-blue"></div>
                    </div>
                  ) : (
                    <div className="mr-2.5 size-4 rounded-full border-[1.5px] border-kd-lte-blue"></div>
                  )}

                  {editingReportId === report.id ? (
                    <input
                      ref={inputRef}
                      type="text"
                      value={editingReportName}
                      onChange={handleEditReportNameChange}
                      onKeyDown={(e) => handleKeyPress(e, true)}
                      className="w-full bg-transparent text-xs text-gray-700 focus:outline-none"
                    />
                  ) : (
                    <span className="text-xs">
                      {report.name}
                      {report.type === "shared" && " (shared)"}
                    </span>
                  )}
                </button>

                <div className="flex items-center">
                  {hoveredReportId === report.id &&
                    editingReportId !== report.id && (
                      <button
                        onClick={() => handleEditReport(report.id, report.name)}
                        className="mr-2 text-gray-400 hover:text-gray-600"
                      >
                        <FaPencilAlt size={12} />
                      </button>
                    )}
                  <button
                    onClick={() => setShowOptionsForId(report.id)}
                    className="text-gray-400 hover:text-gray-600"
                  >
                    <FaEllipsisV size={12} />
                  </button>
                </div>

                {showOptionsForId === report.id && (
                  <div className="absolute right-10 top-0 z-20 mt-2 w-40 rounded-lg border border-gray-200 bg-white shadow-lg">
                    <div className="flex flex-col py-1">
                      <button
                        onClick={() => handleEditReport(report.id, report.name)}
                        className="px-4 py-2 text-left text-xs text-gray-700 hover:bg-gray-100"
                      >
                        Edit
                      </button>
                      <button
                        onClick={() => handleReportSelect(report.id)}
                        className="px-4 py-2 text-left text-xs text-gray-700 hover:bg-gray-100"
                      >
                        Select
                      </button>
                      <button
                        onClick={() => handleEditReport(report.id, report.name)}
                        className="px-4 py-2 text-left text-xs text-gray-700 hover:bg-gray-100"
                      >
                        Rename
                      </button>
                      <div className="my-1 border-t border-dashed border-gray-200"></div>
                      <button
                        onClick={() => handleDeleteReport(report.id)}
                        className="px-4 py-2 text-left text-xs text-[#FA5A7D] hover:bg-gray-100"
                      >
                        Delete
                      </button>
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default CustomReportDropdown;
