"use client";
import { useState, useEffect } from "react";
import cn from "@/utils/cn";
import Image from "next/image";
import EAEIcon from "@/assets/icons/eae-icon.svg";
import Link from "next/link";
import { usePathname } from "next/navigation";
import { EAELogo } from "@/assets/svg-icons/svg-icons";
import { useZStore } from "@/utils/z-store";
import TextComponent from "./TextComponent";
import { GoSidebarCollapse, GoSidebarExpand } from "react-icons/go";

type NavItem = {
  name: string;
  route: string;
  defaultIcon: string;
  activeIcon: string;
  isDisabled?: boolean;
};

type SideNavProps = {
  navs: NavItem[];
  className?: string;
};

const SideNav = ({ navs, className = "" }: SideNavProps) => {
  const pathname = usePathname();
  const { data } = useZStore();
  const [shouldRender, setShouldRender] = useState(false);
  const [collapseSideNav, setCollapseSideNav] = useState(false); // State for collapse

  useEffect(() => {
    setShouldRender(data.sideBarVisible);
  }, [data.sideBarVisible]);

  const hiddenSideNav = [
    "edit",
    "form-builder",
    "download-report",
    "test-edit",
  ];
  const route = pathname.split("/")[2];

  if (hiddenSideNav.includes(route) || !shouldRender) return null;

  // Function to toggle collapse state
  const toggleCollapse = () => {
    setCollapseSideNav((prev) => !prev);
  };

  return (
    <div
      className={cn(
        "h-full bg-white text-black px-[18px] md:px-[23px] xl:px-[46px] xl:py-[43px] py-[21.5px]",
        "transition-all duration-300 ease-in-out",
        "opacity-100 translate-x-0",
        "flex flex-col justify-between w-fit",
        className,
        // {
        //   // Conditional widths based on collapse state
        //   "w-[75px] md:w-[80px] xl:w-[80px] 2xl:w-[100px]": collapseSideNav,
        //   "w-[230px] md:w-[182px] xl:w-[230px] 2xl:w-[345px]": !collapseSideNav,
        // }
      )}
    >
      <div className="">
        <Logo collapseSideNav={collapseSideNav} />
        <NavList
          navs={navs}
          pathname={pathname}
          modalName={data.modal.modalName || ""}
          collapseSideNav={collapseSideNav} // Pass collapse state
        />
      </div>
      <TextComponent
        type="h3"
        className="mx-auto flex w-fit cursor-pointer items-center space-x-5 font-normal md:mx-0"
        onClick={toggleCollapse}
      >
        {collapseSideNav ? (
          <GoSidebarCollapse className="size-4 xl:size-6" />
        ) : (
          <GoSidebarExpand className="size-4 xl:size-6" />
        )}
        {!collapseSideNav && (
          <div className="hidden md:block">Collapse Menu</div>
        )}
      </TextComponent>
    </div>
  );
};

const Logo = ({ collapseSideNav }: { collapseSideNav: boolean }) => (
  <div
    className={cn(
      "mb-[58px] md:mb-[68px] xl:mb-[90px]",
      "transition-all duration-300 ease-in-out",
      {
        // Adjust logo size or visibility based on collapse
        "flex justify-center": collapseSideNav,
        "flex items-center md:w-[126px] xl:w-[256px]": !collapseSideNav,
      },
    )}
  >
    {!collapseSideNav ? (
      <>
        <Image
          alt=""
          src={EAEIcon}
          className="hidden object-contain md:block"
        />
      </>
    ) : (
      <EAELogo />
    )}
  </div>
);

const NavList = ({
  navs,
  pathname,
  modalName,
  collapseSideNav,
}: {
  navs: NavItem[];
  pathname: string;
  modalName: string;
  collapseSideNav: boolean;
}) => (
  <ul className="space-y-5 md:space-y-[21.3px] xl:space-y-[32px]">
    {navs.map((nav, index) => (
      <NavItemComponent
        key={index}
        nav={nav}
        pathname={pathname}
        modalName={modalName}
        collapseSideNav={collapseSideNav} // Pass collapse state
      />
    ))}
  </ul>
);

const NavItemComponent = ({
  nav,
  pathname,
  modalName,
  collapseSideNav,
}: {
  nav: NavItem;
  pathname: string;
  modalName: string;
  collapseSideNav: boolean;
}) => {
  const { name, route, activeIcon, defaultIcon, isDisabled } = nav;
  const isLinkDisabled =
    nav.name !== "Dashboard" &&
    (isDisabled ||
      modalName === "mailchimp-api-key" ||
      modalName === "dashboard-welcome");

  return (
    <div className="group relative">
      <Link
        href={isLinkDisabled ? "#" : route}
        className={cn(
          // "px-3 2xl:px-[30px] md:px-[19px]",
          "flex items-center group-hover:bg-kd-lte-blue py-3 xl:py-[18.5px] w-fit md:rounded-[12px] rounded-[10.19px] xl:rounded-[18px]",
          "transition-all duration-300 ease-in-out",
          "px-4 space-x-3",
          {
            "bg-kd-lte-blue": pathname === route,
            "opacity-50 group-hover:bg-[#8E8E9380] text-[#979797] cursor-not-allowed":
              isLinkDisabled,
          },
        )}
      >
        <NavIcon
          className={""}
          defaultIcon={defaultIcon}
          activeIcon={activeIcon}
          isActive={pathname === route}
        />
        {/* Hide text when collapsed */}
        {!collapseSideNav && (
          <NavText name={name} isActive={pathname === route} />
        )}
      </Link>
      {isDisabled && <ComingSoonTooltip />}
    </div>
  );
};

const NavIcon = ({
  defaultIcon,
  activeIcon,
  isActive,
  className,
}: {
  defaultIcon: string;
  activeIcon: string;
  isActive: boolean;
  className?: string;
}) => (
  <div
    className={cn(
      "size-[16.5px] md:size-[18px]  xl:size-[30px]",
      // "md:mr-[16px] 2xl:mr-[22.5px]",
      className,
    )}
  >
    <Image
      src={defaultIcon}
      alt="icon"
      className={cn("group-hover:hidden", { hidden: isActive })}
    />
    {activeIcon && (
      <Image
        src={activeIcon}
        alt="icon"
        className={cn("hidden group-hover:block", { block: isActive })}
      />
    )}
  </div>
);

const NavText = ({ name, isActive }: { name: string; isActive: boolean }) => (
  <TextComponent
    type="h3"
    className={cn(
      "text-[#231F20] group-hover:text-white group-hover:font-semibold  md:flex hidden font-normal",
      "transition-all duration-300 ease-in-out",
      { "text-white font-semibold": isActive },
    )}
  >
    {name}
  </TextComponent>
);

const ComingSoonTooltip = () => (
  <div className="absolute bottom-full left-7 hidden group-hover:block">
    <span className="relative rounded-lg bg-[#336DFF] px-2 py-1 text-xs text-white">
      Coming Soon
      <span className="absolute left-[18px] top-full size-0 -translate-x-1/2 border-x-8 border-t-8 border-x-transparent border-t-[#336DFF]"></span>
    </span>
  </div>
);

export default SideNav;
