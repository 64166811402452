import { signOut } from "next-auth/react";
import { FaUser } from "react-icons/fa";
import Link from "next/link"; // Import Next.js Link
import Image from "next/image";
import UserProfileIcon from "../icons/user-profile-icon-blue.svg";
import { useEffect, useRef } from "react";
import cn from "@/utils/cn";

const UserProfileSidebar = ({
  isOpen,
  onClose,
  userName,
  userRole,
  userEmail,
}: {
  isOpen: boolean;
  onClose: () => void;
  userName: string;
  userRole: string;
  userEmail: string;
}) => {
  const sidebarRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        sidebarRef.current &&
        !sidebarRef.current.contains(event.target as Node)
      ) {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen, onClose]);

  if (!isOpen) return null;

  // Define links for each menu item
  const menuItems = [
    { label: "Profile", href: "/dashboard/settings/profile" },
    { label: "Account", href: "/dashboard/settings/account" },
    { label: "Billing", href: "/dashboard/settings/billing" },
    { label: "Users", href: "/dashboard/settings/users" },
    { label: "Tools", href: "/dashboard/settings/tools" },
    { label: "Logout", href: "#" }, // Logout will be handled by signOut
  ];

  return (
    <div
      ref={sidebarRef}
      className={cn(
        "fixed inset-y-0 right-10 top-32 z-50 h-fit w-64 rounded-[16px] border border-kd-lte-blue bg-white shadow-lg transition-transform duration-300 ease-in-out",
        "w-[280px]",
      )}
    >
      <div className="">
        <div className=" flex items-center p-4">
          <div className="flex size-12 items-center justify-center rounded-full text-white">
            <Image alt="" src={UserProfileIcon} />
          </div>
          <div className="ml-4 flex flex-col space-y-1 font-medium">
            <div className=" flex  space-x-2">
              <h2 className="text-lg font-semibold">{userName}</h2>
              <span className="rounded bg-gray-200 px-2 py-1 text-sm text-gray-600">
                {userRole}
              </span>
            </div>
            <p className=" text-sm text-[#96A5B8]">{userEmail}</p>
          </div>
        </div>

        <nav>
          <ul className="divide-y">
            {menuItems.map((item, index) => (
              <li
                key={index}
                className="px-[26px]py-[15px] h-[60px] text-[12px] hover:bg-[#EDF2F6] hover:text-kd-lte-blue"
              >
                {item.label === "Logout" ? (
                  <button
                    onClick={() => {
                      signOut({ callbackUrl: "/" });
                      localStorage.clear();
                    }}
                    className="block w-full rounded px-6 py-[18px] text-left text-red-500 "
                  >
                    {item.label}
                  </button>
                ) : (
                  <Link
                    href={item.href}
                    className="block h-full rounded px-6 py-[18px]"
                  >
                    {item.label}
                  </Link>
                )}
              </li>
            ))}
          </ul>
        </nav>

        <div className="px-[26px] pb-4 pt-2 text-[10px] text-[#96A5B8]">
          <Link href="#" className="block hover:text-gray-700">
            <u>Privacy</u> and <u>Terms</u>
          </Link>

          <Link href="#" className="block hover:text-gray-700">
            Cookie Preferences
          </Link>
        </div>
      </div>
    </div>
  );
};

export default UserProfileSidebar;
