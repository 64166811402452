import jsPDF from "jspdf";
import * as htmlToImage from "html-to-image";

export async function handleExport() {
  const doc = new jsPDF("landscape", "px", "a4", false);

  const elements = document.getElementsByClassName("export-pdf");

  await creatPdf({ doc, elements });

  doc.save(`charts.pdf`);
}

async function creatPdf({
  doc,
  elements,
}: {
  doc: jsPDF;
  elements: HTMLCollectionOf<Element>;
}) {
  const padding = 10;
  const marginTop = 20;
  const pageWidth = doc.internal.pageSize.getWidth();
  const pageHeight = doc.internal.pageSize.getHeight();
  const numColumns = 4;
  const columnWidth = (pageWidth - padding * 2) / numColumns;

  let top = marginTop;
  let left = padding;
  let maxRowHeight = 0;

  for (let i = 0; i < elements.length; i++) {
    const el = elements.item(i) as HTMLElement;
    const imgData = await htmlToImage.toPng(el);

    const elHeight = el.offsetHeight;
    const elWidth = el.offsetWidth;

    const colSpan = getColSpan(el);
    const scaledWidth = columnWidth * colSpan - padding * 2;
    const scaledHeight = (elHeight * scaledWidth) / elWidth;

    if (left + scaledWidth > pageWidth) {
      top += maxRowHeight + marginTop;
      left = padding;
      maxRowHeight = 0;
    }

    if (top + scaledHeight > pageHeight) {
      doc.addPage();
      top = marginTop;
      left = padding;
      maxRowHeight = 0;
    }

    doc.addImage(
      imgData,
      "PNG",
      left,
      top,
      scaledWidth,
      scaledHeight,
      `image${i}`,
    );

    left += scaledWidth + padding;
    maxRowHeight = Math.max(maxRowHeight, scaledHeight);
  }
}

function getColSpan(el: HTMLElement): number {
  if (el.classList.contains("col-span-3")) return 3;
  if (el.classList.contains("col-span-2")) return 2;
  return 1;
}

export async function exportDashboardReport() {
  const dashboardElement = document.getElementById("dashboard-container");
  if (!dashboardElement) {
    console.error("Dashboard element not found");
    return;
  }

  // Create a new PDF document
  const doc = new jsPDF("portrait", "px", "a4", false);

  // Capture the dashboard element as an image with high resolution
  const options = {
    quality: 2,
    width: dashboardElement.offsetWidth,
    filter: (node: any) => {
      return !node.classList?.contains("no-print");
    },
  };

  const imgData = await htmlToImage.toPng(dashboardElement, options);

  // Calculate the aspect ratio to fit the image on the PDF page
  const aspectRatio =
    dashboardElement.offsetWidth / dashboardElement.offsetHeight;
  const pageWidth = doc.internal.pageSize.getWidth();
  const pageHeight = doc.internal.pageSize.getHeight();

  // Adjust these values to center the content
  let imgWidth = pageWidth; // Use full page width
  let imgHeight = imgWidth / aspectRatio;

  // If the height exceeds the page height, adjust the size to fit
  if (imgHeight > pageHeight) {
    imgHeight = pageHeight;
    imgWidth = imgHeight * aspectRatio;
  }

  // Calculate x position to center the image
  const xPosition = (pageWidth - imgWidth) / 2;

  // Add the image to the PDF with centered positioning
  doc.addImage(imgData, "PNG", xPosition, 0, imgWidth, imgHeight, "dashboard");

  // Save the PDF with the name "dashboard-report.pdf"
  doc.save("dashboard-report.pdf");
}
