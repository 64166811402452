"use client";
import Button from "@/components/buttons";
import Image from "next/image";
import { usePathname, useRouter } from "next/navigation";
import EditPencil from "../icons/edit-pencil.svg";
import ExportReportIcon from "../icons/export-report.svg";
import UserProfileIcon from "../icons/user-profile-icon.svg";
import { IoIosArrowDown } from "react-icons/io";
import Link from "next/link";
import { exportDashboardReport } from "../utils/export-report-functions";
import cn from "@/utils/cn";
import UserProfileSidebar from "./UserProfileSidebar";
import { useEffect, useState } from "react";
import { useZStore } from "@/utils/z-store";
import DashboardDropdown from "./DashboardDropdown";
import CustomReportDropdown from "./CustomReportDropdown";
import ShareDropdown from "./ShareDropdown";
import { useSession } from "next-auth/react";
import axios from "axios";
import BrandIcon from "@/assets/icons/eae-icon.svg";
import { DownloadReportIcon, EditReportIcon } from "../icons";
import TextComponent from "@/app/_components/TextComponent";
const Header = ({ className }: { className?: string }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const { data } = useZStore();
  const { data: session } = useSession();
  const pathName = usePathname();
  const route = pathName.split("/")[2];

  const [profilePicture, setProfilePicture] = useState(
    session?.user?.image || "",
  );

  useEffect(() => {
    const getProfilePicture = async () => {
      try {
        const response = await axios.get("/api/profile-picture");
        setProfilePicture(response.data.profilePicture);
      } catch (error) {
        console.error("Error fetching profile picture:", error);
      }
    };

    getProfilePicture();
  }, []);

  if (route.toLocaleLowerCase() === "edit") return <EditDashboardHeader />;
  if (route.toLocaleLowerCase() === "form-builder")
    return <FormBuilderHeader />;

  if (route.toLocaleLowerCase() == "report-builder") {
    return <EditReport title="Edit Report" />;
  }

  if (route.toLocaleLowerCase() == "download-report") {
    return <DownloadReportHeader title="Custom Report" />;
  }
  if (route.toLocaleLowerCase() === "reports") {
    return <ReportsHeader title="Reports" />;
  }
  const modalName = data.modal.modalName;
  const isModalActive =
    modalName === "mailchimp-api-key" || modalName === "dashboard-welcome";

  return (
    <div
      className={cn(
        "flex h-[60px] items-center justify-between bg-white px-5 xl:h-[120px] 2xl:px-10",
        isModalActive ? "bg-black/[20%] pointer-events-none opacity-50" : "",
        className,
      )}
      style={isModalActive ? { filter: "grayscale(100%)" } : {}}
    >
      <div className="flex">
        <Text className="font-semibold capitalize text-kd-lte-blue">
          {route}
        </Text>
      </div>

      <div className="flex items-center justify-center space-x-[11px] 2xl:space-x-[22px]">
        {route.toLocaleLowerCase() !== "settings" && (
          <div className="ml-10 hidden space-x-[22px] md:flex">
            <Link href="/dashboard/edit">
              <Button
                variant="secondary"
                // className="h-[60px] rounded-2xl bg-[#F9FAFB] px-4 text-base text-[#05004E]"
                className={cn(
                  "md:h-[30px] font-medium md:rounded-lg md:text-[8px] xl:h-[60px]  xl:rounded-[16px]  bg-[#F9FAFB] text-[#05004E] text-nowrap px-2 ",
                )}
              >
                <Image alt="" src={EditPencil} className="size-3 xl:size-6" />
                <TextComponent
                  type="h4"
                  className="ml-1 text-[#05004E] 2xl:ml-2.5"
                >
                  Edit Dashboard
                </TextComponent>
              </Button>
            </Link>
            <div className="">
              <DashboardDropdown invertColors={false} />
            </div>
          </div>
        )}
        <div
          className="flex cursor-pointer items-center justify-center space-x-4"
          onClick={() => setIsSidebarOpen(!isSidebarOpen)}
        >
          <div className="size-6 overflow-hidden rounded-full xl:size-[32px] 2xl:size-12">
            {profilePicture || session?.user?.image ? (
              // eslint-disable-next-line @next/next/no-img-element
              <img
                src={profilePicture || session?.user?.image || UserProfileIcon}
                alt="Profile"
                className="size-full object-cover"
              />
            ) : (
              // eslint-disable-next-line @next/next/no-img-element
              <Image
                src={UserProfileIcon}
                alt="Default User Icon"
                className="size-full object-cover"
              />
            )}
          </div>
          <div className="hidden flex-col md:flex">
            <div className="flex items-center text-[8px] font-medium leading-3 text-[#05004E] xl:text-[10.6px] 2xl:text-base 2xl:leading-[24px]">
              {data?.userDetails?.userName?.split(" ")[0] || "User"}
              <IoIosArrowDown className="ml-2 size-3 2xl:size-6" />
            </div>
            <span className="text-left text-[7px] font-medium leading-[10px] text-[#737791] xl:text-[10.6px] 2xl:text-[14px] 2xl:leading-[20px]">
              {data?.userDetails?.role}
            </span>
          </div>
        </div>
      </div>

      <UserProfileSidebar
        isOpen={isSidebarOpen}
        onClose={() => setIsSidebarOpen(false)}
        userName={data?.userDetails?.userName?.split(" ")?.[0] || "user"}
        userRole={data?.userDetails?.role || "user"}
        userEmail={data?.userDetails?.email || "user"}
      />
    </div>
  );
};

export default Header;

const DownloadReportHeader = ({ title }: { title: string }) => {
  return <></>;
  return (
    <div className=" flex h-20 w-full items-center justify-between bg-[#FAFBFC] px-[23px] shadow-sm md:h-[60px] xl:h-[120px] xl:px-10">
      <div className="flex space-x-2">
        {/* <Image alt="icon" src={BrandIcon} /> */}
        <Text className="capitalize text-kd-lte-blue">{title}</Text>
      </div>
      <div className="flex space-x-4">
        <Button
          className="h-[30px] w-fit space-x-2 rounded-lg border bg-white text-base text-kd-lte-blue hover:border-kd-lte-blue xl:h-[60px] xl:rounded-2xl"
          onClick={() => {
            exportDashboardReport();
          }}
        >
          <Image alt="" src={DownloadReportIcon} className="size-3 xl:size-5" />
          <TextComponent type="h4">Download</TextComponent>
        </Button>
      </div>
    </div>
  );
};
const EditDashboardHeader = ({
  title = "Edit Dashboard",
}: {
  title?: string;
}) => {
  return (
    <div className=" flex h-20 w-full items-center justify-between bg-kd-lte-blue px-[23px] md:h-[60px] xl:h-[120px] xl:px-10">
      <Text className="capitalize text-white">{title}</Text>
      <div className="">
        <DashboardDropdown showCreateOption={true} invertColors={true} />
      </div>
    </div>
  );
};

const EditReport = ({ title = "Edit Report" }: { title?: string }) => {
  const { data } = useZStore();
  return (
    // <div className=" flex h-20 w-full items-center justify-between bg-kd-lte-blue px-[23px] md:h-[60px] xl:h-[120px] xl:px-10">
    <div
      className={cn(
        "flex h-[60px] w-full items-center justify-between bg-kd-lte-blue px-[23px] md:h-[60px] xl:h-[80px] xl:px-10 2xl:h-[120px]",
      )}
    >
      <Text className="capitalize text-white">{title}</Text>
      {!data.modal.isOpen && <CustomReportDropdown invertColors={true} />}
    </div>
  );
};
const FormBuilderHeader = ({
  title = "Edit Audit Form",
}: {
  title?: string;
}) => {
  return (
    <div className=" flex h-[60px] w-full items-center bg-kd-lte-blue px-[23px] md:h-[60px] xl:h-[120px] xl:px-10">
      {/* <Text className="text-[18px] capitalize text-white xl:text-[24px] 2xl:text-[38px]"> */}
      <TextComponent type="h2" className="text-white">
        {title}
      </TextComponent>
      {/* </Text> */}
    </div>
  );
};

const Text = ({
  children,
  className = "",
}: {
  children: React.ReactNode;
  className?: string;
}) => {
  return (
    <TextComponent type="h2" className={cn("text-kd-lte-blue", className)}>
      {children}
    </TextComponent>
    // <h1
    //   className={cn(
    //     `text-left text-[18px] font-semibold leading-[25.2px] md:text-[18px] md:leading-[25.2px] xl:text-[24px] xl:leading-[33.6px] 2xl:text-[36px] 2xl:leading-[50.4px]`,
    //     className,
    //   )}
    // >
    //   {children}
    // </h1>
  );
};

const ReportsHeader = ({ title = "Reports" }: { title?: string }) => {
  const { data } = useZStore();
  const { data: session } = useSession();
  const router = useRouter();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const [profilePicture, setProfilePicture] = useState(
    session?.user?.image || "",
  );

  useEffect(() => {
    const getProfilePicture = async () => {
      try {
        const response = await axios.get("/api/profile-picture");
        setProfilePicture(response.data.profilePicture);
      } catch (error) {
        console.error("Error fetching profile picture:", error);
      }
    };

    getProfilePicture();
  }, []);

  return (
    <div className=" flex h-20 w-full items-center justify-between bg-white px-[23px] shadow-sm md:h-[60px] xl:h-[120px] xl:px-10">
      <div className="flex space-x-2">
        <Text className="capitalize text-kd-lte-blue">{title}</Text>
      </div>
      <div className="flex space-x-6">
        <div className="hidden space-x-4 md:flex">
          <Button
            variant="secondary"
            className="h-[30px] w-fit space-x-2 rounded-[8px] border bg-[#F9FAFB] px-2 text-base text-kd-lte-blue hover:border-kd-lte-blue xl:h-[60px] xl:rounded-2xl"
            onClick={() => {
              window.open(
                "/dashboard/download-report",
                "_blank",
                "noopener,noreferrer",
              );
            }}
          >
            <Image alt="" src={ExportReportIcon} />
            <TextComponent className="text-[#05004E]" type="h4">
              Export Report
            </TextComponent>
          </Button>

          <Button
            variant="secondary"
            className="h-[30px] w-fit space-x-2 rounded-[8px] border bg-[#F9FAFB] text-base text-kd-lte-blue hover:border-kd-lte-blue xl:h-[60px] xl:rounded-2xl"
            onClick={() => {
              router.push("/dashboard/report-builder");
            }}
          >
            <Image alt="" src={EditReportIcon} />
            <TextComponent className="text-[#05004E]" type="h4">
              Edit Report
            </TextComponent>
          </Button>

          <ShareDropdown />
          <CustomReportDropdown invertColors={false} />
        </div>
        <div
          className="flex cursor-pointer items-center justify-center space-x-4"
          onClick={() => setIsSidebarOpen(!isSidebarOpen)}
        >
          <div className="size-6 overflow-hidden rounded-full xl:size-[32px] 2xl:size-12">
            {profilePicture || session?.user?.image ? (
              // eslint-disable-next-line @next/next/no-img-element
              <img
                src={profilePicture || session?.user?.image || UserProfileIcon}
                alt="Profile"
                className="size-full object-cover"
              />
            ) : (
              // eslint-disable-next-line @next/next/no-img-element
              <Image
                src={UserProfileIcon}
                alt="Default User Icon"
                className="size-full object-cover"
              />
            )}
          </div>
          <div className="hidden flex-col md:flex">
            <div className="flex items-center text-[8px] font-medium leading-3 text-[#05004E] xl:text-[10.6px] 2xl:text-base 2xl:leading-[24px]">
              {data?.userDetails?.userName?.split(" ")[0] || "User"}
              <IoIosArrowDown className="ml-2 size-3 2xl:size-6" />
            </div>
            <span className="text-left text-[7px] font-medium leading-[10px] text-[#737791] xl:text-[10.6px] 2xl:text-[14px] 2xl:leading-[20px]">
              {data?.userDetails?.role}
            </span>
          </div>
        </div>

        <UserProfileSidebar
          isOpen={isSidebarOpen}
          onClose={() => setIsSidebarOpen(false)}
          userName={data?.userDetails?.userName?.split(" ")?.[0] || "user"}
          userRole={data?.userDetails?.role || "user"}
          userEmail={data?.userDetails?.email || "user"}
        />
      </div>
    </div>
  );
};
